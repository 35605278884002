import {
  CalendarPermissions,
  SettingsPermissions,
  UserManagementPermissions,
  CoursesPermissions,
} from '../lib/types'
import { TAttachment } from '../models/attachments'
import { TLanguage } from '../models'

export type IDefaultCourseSettings = {
  completeDays: number
  quizQuestions: number
  quizAttempts: number
  passingScore: number
  watchPercentage: number
  enforceOrder: boolean
  attestationStatement: boolean
  attestationStatementText: string
  certificateId?: number | null | undefined
  signature: TAttachment | Blob | undefined
  signatoryId?: number | null | undefined
  signatory:
    | {
        id: number
        firstName: string
        lastName: string
      }
    | undefined
    | null
  signatoryPosition: null | undefined | string
  messages: {
    success: string
    retake: string
    fail: string
  }
  languages: TLanguage[]
  digitalCoursesChanged?: { userId: number; digitalCourseReplaced: boolean }[]
  coursesExpiring?: { userId: number; courseExpiringWithNoRevision: boolean }[]
}

export type DefaultCourseSettingsPermissions =
  | SettingsPermissions.VIEW_DEFAULT_COURSE_SETTINGS
  | CalendarPermissions.VIEW_FACILITATOR_COURSE_VIEW
  | UserManagementPermissions.VIEW_USER_COURSE_HISTORY
  | CoursesPermissions.ADD_EDIT_COURSE

export type ICerticateTemplate = {
  id: number
  preview: string
}

export namespace GetDefaultCourseSettingsDTO {
  export type Request = {
    permission: DefaultCourseSettingsPermissions
  }
  export type Response = IDefaultCourseSettings
}

export namespace GetCertificateTemplatesDTO {
  export type Response = ICerticateTemplate[]
}

export namespace PutDefaultCourseSettingsDTO {
  export type Request = IDefaultCourseSettings
  export type Response = IDefaultCourseSettings
}
